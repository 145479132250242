@import '../../../styles/mixins.scss';

//BookMidBtn
.bookMidBtn {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  padding: 12.5px 21.5px 13px;
  background: linear-gradient(180deg, #f7e076 0%, #f19001 100%);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  border: none;
  cursor: pointer;
  @include max-screen-530 {
    padding: 12px 12px 14px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(240, 145, 1, 0.2);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &_mobFull {
    @include max-screen-530 {
      width: 100%;
      text-align: center;
      padding: 12.5px 10px 13px;
    }
  }

  &_small {
    margin-right: 0;
    padding: 9px 14px 11px;
    font-weight: normal;
    @include max-screen-530 {
      padding: 9px 14px 11px;
    }
  }
}
