@import '../../../styles/mixins.scss';

//Error Block
.errorBlock {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  @include max-screen-530 {
    margin-bottom: 45px;
  }

  &__desk {
    flex: 1;
  }

  &__img {
    position: relative;
    width: 300px;
    height: auto;
    margin-right: 20px;
    background-color: #f0f0f0;
    overflow: hidden;
    @include max-screen-980 {
      width: 220px;
    }
    @include max-screen-530 {
      width: 100%;
      height: 132px;
      margin: 0 0 20px 0;
    }

    &:before {
      content: '';
      position: absolute;
      width: 316px;
      height: 144px;
      left: 73%;
      bottom: 0;
      transform: translateX(-50%);
      background: url('/icons/book-404.svg') no-repeat 0 0;
      background-size: cover;
      @include max-screen-980 {
        width: 324px;
        height: 150px;
        left: 82%;
      }
      @include max-screen-530 {
        width: 256px;
        height: 117px;
        left: 66%;
      }
    }
  }
  &__title {
    margin: -5px 0 10px;
    @include max-screen-530 {
      margin: 0 0 10px 0;
    }
  }
  &__txt {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
  }
}
